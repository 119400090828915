import usImage from "../../../assets/images/serviceImages/itService/us.png";
import utilityImage from "../../../assets/images/serviceImages/itService/utility.png";
// import Plan from "../../../assets/icons/plan.png";
// import Design from "../../../assets/icons/design.png";
// import Develop from "../../../assets/icons/develop.png";

import Vector from "../../../assets/icons/Vector.png";
import alertCircle from "../../../assets/icons/alert-circle-sharp.png";
import implementation from "../../../assets/icons/implementation.png";
import ucc from "../../../assets/images/serviceImages/ucc.png";
import loan from "../../../assets/images/serviceImages/loan.png";
import creditCard from "../../../assets/images/serviceImages/credit-card.png";
import credit from "../../../assets/images/serviceImages/credit.png";
import integration from "../../../assets/images/serviceImages/integration.png";

export const itContent={
    firstSection:{
        title:"Empowering Your Business with Cutting-Edge IT and Web Development Solutions",
        content:"At Fusiontecz, we don’t just provide IT and web development services—we transform your business. Whether you need digital transformation for your enterprise, customized software solutions, or advanced AI applications, we deliver innovative technology that drives your success. As a leading web and software development company, Fusiontecz is where your journey to digital excellence begins."
    },
    secondSection:{

    },
    thirdSection:{
        utility:{
            title:"Your Partner in IT and Web Development Excellence",
            content:"Fusiontecz accelerates your business’s digital transformation with a comprehensive suite of IT and web development services. From designing sleek, user-friendly websites to developing complex, custom database software solutions, we ensure your business thrives in the digital landscape.",
            image: utilityImage,
        },
        us:{
            title:"Digital Transformation for Enterprises",
            content:"We specialize in guiding enterprises through digital transformation, ensuring they stay competitive and agile in a rapidly evolving market. Our solutions, including customer management software and CRM services, are tailored to meet the unique challenges of your business, helping you harness the full potential of technology.",
            image: usImage,
        }
    },
    fourthSection:{
        title:"Strategic Web Development in Action",
        subTitle:"At Fusiontecz, we follow a proven process to elevate your digital presence. Our website development software services are designed to maximize your impact and drive growth.",
        parah:"With Fusiontecz, your digital growth is always strategically planned and expertly executed, making us a trusted partner in your digital journey",
        items:[
            {
                title:"Plan",
                image:Vector,
                about:"We begin by understanding your business goals and target audience to create a customized development strategy."
            },
            {
                title:"Design",
                image:alertCircle,
                about:"Our design experts use the best web designing software to craft visually appealing and intuitive websites that resonate with your brand’s identity."
            },
            {
                title:"Develop",
                image:implementation,
                about:"We build robust, scalable websites and applications using the latest technologies, ensuring performance and security."
            }
        ]
    },
    fifthSection:{
        title:"Comprehensive Digital Marketing Services",
        content:"Beyond social media, Fusiontecz offers a full suite of digital marketing services in Toronto designed to elevate every aspect of your brand’s online presence. We specialize in",
        items:[
            {
                title:"Customized Software Solutions",
                image:ucc,
                about:"Tailor-made software that meets your specific business requirements, enhancing efficiency and productivity.",
                btnContent:"Consult with us now!"
            },
            {
                title:"App Development & AI Solutions",
                image:creditCard,
                about:"Build user-friendly apps and automate processes with AI to drive growth.",
                btnContent:"Consult with us now!"
            },
            {
                title:"CRM & E-Commerce Solutions",
                image:loan,
                about:"Streamline your customer management with advanced CRM services and boost online sales with tailored e-commerce platforms.",
                btnContent:"Consult with us now!"
            },
            {
                title:"ERP Systems",
                image:credit,
                about:"Optimize your business operations with integrated ERP solutions that provide real-time data and analytics.",
                btnContent:"Consult with us now!"
            }
        ]
    },
    sixthSection:{
        title:"Transform Your Business Digitally",
        content:"At Fusiontecz, we empower your business through innovative IT and web development solutions. Whether you're seeking web development outsourcing companies or need the best web designing software for your project, our streamlined processes make it easy for you to harness the power of technology, allowing you to focus on what matters most—growing your business. Partner with us to achieve lasting digital success.",
        btnContent:"Start your digital journey today!"
    },
    seventhSection:{
        title:{
            spanContent:"Your All-in-One",
            title: "IT and Web Development Partner"
         },
        content:"At Fusiontecz, we’re dedicated to delivering technology solutions that drive your business forward. Our services are designed to support your growth across all digital aspects.",
        items:[
            {
                title:"Customized Software Development",
                image:integration,
                content:"Get the exact tools your business needs to operate efficiently and effectively."
            },
            {
                title:"Advanced Web & App Development",
                image:integration,
                content:"Build a strong online presence with our expert development services."
            },
            {
                title:"AI and Automation Solutions",
                image:integration,
                content:"Leverage AI to streamline operations and improve decision-making."
            },
            {
                title:"CRM and E-Commerce Platforms",
                image:integration,
                content:"Enhance customer engagement with customer management software and boost online sales with tailored solutions."
            },
            {
                title:"Ongoing IT Support",
                image:integration,
                content:"Ensure your systems are secure and reliable with our professional IT support services."
            },
            {
                title:"ERP Implementation",
                image:integration,
                content:"Optimize your operations with ERP systems that provide comprehensive business insights."
            }
        ],
        ending:"Unlock your business's full potential with Fusiontecz—your trusted partner in digital transformation and IT excellence.",
        btnContent:"Contact us today!"
    },
}