import React from "react";
import "../HeroSection/heroSection.css";
import "../services/services.css";
import NavbarServices from "../../navbar-services/NavbarServices";
import heroBuilding from "../../../assets/backgrounds/heroBuilding.png";

import knime  from "../../../assets/images/serviceImages/knime.png";
import spark  from "../../../assets/images/serviceImages/spark.png";
import looker  from "../../../assets/images/serviceImages/looker.png";
import ibm  from "../../../assets/images/serviceImages/ibm.png";
import PowerBi  from "../../../assets/images/serviceImages/PowerBI.png";
import tableau  from "../../../assets/images/serviceImages/tableau.png";
import sas  from "../../../assets/images/serviceImages/sas.png";
import googleAnalytic from "../../../assets/images/serviceImages/googleAnalytic.png";

export default function HeroSection(props){
    const items=[
        {image:googleAnalytic},
        {image:sas},
        {image:tableau},
        {image:PowerBi},
        {image:ibm},
        {image:looker},
        {image:spark},
        {image:knime},
    ];
    return(
        <>
            <div className="heroSection-mainContainer">
                <div className="heroSection-container">
                    <NavbarServices/>
                    <div className="hero-section">
                        <div className="heroSection-Content">
                            <h1>{props.title}</h1>
                            <p>
                                {props.content}
                            </p>
                            <button className="GetInTouch-btn">
                                Get in Touch
                            </button>
                        </div>
                    </div>
                </div>
                <div className="heroSection-company">
                    <div className="heroSection-boxes">
                        {
                            items.map((item, index)=>{
                                return(
                                    <div key={index}>
                                        <img src={item.image} alt="company-images" />
                                    </div>
                                );
                            })
                        }
                    </div>
                </div>
                <div className="heroSection-Image">
                    <img src={heroBuilding} alt="service-hero-building-image" />
                </div>
            </div>
        </>
    );
}