import React, {useContext} from 'react'
import ContactComp from "../../components/contactcomp/ContactComp";
import Footer from "../../components/footer/Footer";
import HIWCompOne from "../../components/hiwcomp/hiwcompone/HIWCompOne";
import HIWCompTwo from '../../components/hiwcomp/hiwcomptwo/HIWCompTwo';
import Drawer from '../../components/drawer/Drawer';
import { UserContext } from '../../context/userContext';
import DocumentMeta from 'react-document-meta';
export default function HowItWorksPage() {
    const {drawer} = useContext(UserContext);
    const meta = {
      title: 'Transforming Ideas into Shaping Excellence, Concept to Completion',
      description: 'Fusiontecz is a top web development agency offering web app development and website design services. Find the best ecommerce website developer and web design companies near you.',
      canonical: 'https://www.fusiontecz.com/howitworks',
      meta: {
        charset: 'utf-8',
        name: {
          keywords: 'web development services, web development agency, web app development, web design companies near me, ecommerce website developer, website development services'
        }
      }
    };
  return (
    <div>
      { drawer ? <Drawer/> : <>
        <DocumentMeta {...meta}>
        <HIWCompOne/>
        <HIWCompTwo/>
        <ContactComp/>
        <Footer/>
        </DocumentMeta>
        </>
    }
    </div>
  )
}
