import usImage from "../../../assets/images/serviceImages/fintech/us.png";
import utilityImage from "../../../assets/images/serviceImages/fintech/utility.png";

import Vector from "../../../assets/icons/Vector.png";
import alertCircle from "../../../assets/icons/alert-circle-sharp.png";
import implementation from "../../../assets/icons/implementation.png";
import ucc from "../../../assets/images/serviceImages/ucc.png";
import loan from "../../../assets/images/serviceImages/loan.png";
import creditCard from "../../../assets/images/serviceImages/credit-card.png";
import credit from "../../../assets/images/serviceImages/credit.png";
import integration from "../../../assets/images/serviceImages/integration.png";

export const fintechContent={
    firstSection:{
        title:"Smart Finance Solutions",
        content:"Transform your business with Fusiontecz’s innovative AI solutions, streamlining financial management for competitive growth. Digitize your finances to accelerate success and future-proof your operations."
    },
    secondSection:{

    }, 
    thirdSection:{
        utility:{
            title:"Your Trusted Fintech Consultant",
            content:"Fusiontecz provides expert fintech consulting and solutions tailored to the finance sector's diverse needs. We identify challenges and craft secure, scalable, user-friendly applications to solve them effectively. Our team leverages cutting-edge technologies to optimize financial strategies and drive business growth. Whether you're a startup or an established enterprise, we deliver innovative solutions that enhance your competitive edge. Trust Fusiontecz to guide you in aligning fintech innovations with your business objectives.",
            image: utilityImage,
        },
        us:{
            title:"Your Path to Secure and Strategic Growth",
            content:"At Fusiontecz, we make fintech easy. From tailored Merchant Cash Advance pricing and scrubbers to smart Loan Management Systems, we’ve got you covered. Our Credit Risk Solutions leverage the latest in AI and Machine Learning to keep your finances secure and strategic. Plus, our staff augmentation services ensure you have the right experts on your team to handle it all. Trust us to simplify the complexities and keep your business moving forward.",
            image: usImage
        }
    },
    fourthSection:{
        title:"Strategic Fintech Consulting",
        subTitle:"Transforming Financial Operations for Sustainable Growth",
        parah:"At Fusiontecz, we specialize in driving growth through strategic fintech solutions. We partner with businesses to identify and address financial challenges, leveraging cutting-edge technologies to optimize operations and achieve long-term success.",
        items:[
            {
                title:"Identify Challenges",
                image:Vector,
                about:"Conduct a thorough analysis of financial operations to uncover issues and opportunities."
            },
            {
                title:"Strategic Planning",
                image:alertCircle,
                about:"Develop tailored strategies using advanced technologies to address challenges effectively."
            },
            {
                title:"Implementation",
                image:implementation,
                about:"Design and deploy secure, scalable financial applications that streamline processes."
            }
        ]
    },
    fifthSection:{
        title:"Services We Provide",
        content:"At Fusiontecz, we offer comprehensive financial services tailored to meet the diverse needs of businesses and individuals. Our expertise in advanced fintech solutions enables us to deliver efficient, secure, and innovative services that drive growth and streamline operations. Here’s a glimpse of what we offer:",
        items:[
            {
                title:"Merchant Cash Advances&Loan Solutions",
                image:ucc,
                about:"Advanced loan structuring, including tailored Merchant Cash Advances and mortgages, designed to drive business expansion and achieve financial objectives.",
                btnContent:"Get Started"
            },
            {
                title:"Credit Processing & Accounts Receivables",
                image:loan,
                about:"Innovative Merchant Cash Advance credit processing and accounts receivable strategies that enhance liquidity and ensure strong cash flow management.",
                btnContent:"Get Started"
            },
            {
                title:"Vendor Management & Invoice Optimization",
                image:creditCard,
                about:"Cutting-edge vendor management and invoice processing solutions that streamline operations, reduce costs, and improve overall financial efficiency.",
                btnContent:"Get Started"
            },
            {
                title:"UCC Collections & Payment Solutions",
                image:credit,
                about:"Expert services in UCC collections, asset recovery, and secure payment processing, tailored to protect financial interests and facilitate seamless transactions for large-scale retailers.",
                btnContent:"Get Started"
            }
        ]
    },
    sixthSection:{
        title:"SME Funding & Banking Automation Solutions",
        content:"Fusiontecz offers innovative fintech solutions designed to address the unique challenges of SMEs. We provide seamless funding options and automate banking processes to enhance efficiency, reduce operational costs, and ensure quick access to capital. Our tailored solutions empower small and medium-sized enterprises to scale with confidence and stay competitive in a rapidly evolving financial landscape. Empower Your Growth with Fusiontecz!",
        btnContent:"Get Started today!"
    },
    seventhSection:{
        title:{
           spanContent:"Why Choose",
           title: "Fusiontecz?"
        },
        content:"Fusiontecz goes beyond traditional financial services by providing innovative solutions that simplify and enhance your financial operations",
        items:[
            {
                title:"Easy Integrations",
                image:integration,
                content:"Seamlessly connect with your existing systems to improve operational efficiency and minimize implementation time. Our solutions ensure a smooth transition with minimal disruption to your business processes."
            },
            {
                title:"Billing Solutions",
                image:integration,
                content:"Automate and streamline your billing processes to enhance accuracy and efficiency. Reduce manual effort, eliminate errors, and ensure timely invoicing, improving cash flow and customer satisfaction"
            },
            {
                title:"Risk Management",
                image:integration,
                content:"Implement robust risk management strategies that safeguard your assets and ensure compliance with industry regulations. Our proactive approach helps identify potential threats and mitigate risks effectively."
            },
            {
                title:"Mobile Banking",
                image:integration,
                content:"Provide secure and user-friendly mobile banking solutions that cater to the demands of a digital-first world. Enhance customer engagement and satisfaction with seamless, on-the-go banking services."
            },
            {
                title:"Security and Compliance",
                image:integration,
                content:"Adhere to the highest security standards and regulatory requirements, ensuring the protection of sensitive data. Our comprehensive compliance strategies help you maintain trust and credibility in the marketplace."
            },
            {
                title:"Robotic Process Automation (RPA)",
                image:integration,
                content:"Leverage RPA to automate repetitive tasks, reducing manual workload and increasing operational efficiency. This allows your team to focus on higher-value activities, driving productivity and innovation."
            },
            {
                title:"Analytics and Reporting",
                image:integration,
                content:"Utilize advanced analytics and reporting tools to gain actionable insights into your business operations. Make informed, data-driven decisions that foster growth and enhance performance across all areas."
            }
        ],
        ending:"Ready to Transform your Financial Operations with Fusiontecz'sInnovative Solutions?",
        btnContent:"Contact Us Today!!"
    },
}