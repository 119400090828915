import React, { useContext } from 'react'
import './AboutPage.css'
import Footer from "../../components/footer/Footer";
import AboutCompOne from '../../components/aboutuscomp/aboutcompone/AboutCompOne';
import AboutCompTwo from '../../components/aboutuscomp/aboutcomptwo/AboutCompTwo';
import AboutCompThree from '../../components/aboutuscomp/aboutcompthree/AboutCompThree';
import ContactComp from '../../components/contactcomp/ContactComp';
import { UserContext } from '../../context/userContext';
import Drawer from "../../components/drawer/Drawer";
import DocumentMeta from 'react-document-meta';
export default function AboutPage() {
  const {drawer} = useContext(UserContext);
  const meta = {
    title: 'Driving Innovation with Confidence, Delivering with Precision',
    description: 'Fusiontecz is a top web development agency offering web app development and website design services. Find the best ecommerce website developer and web design companies near you.',
    canonical: 'https://www.fusiontecz.com/about',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: 'web development services, web development agency, web app development, web design companies near me, ecommerce website developer, website development services'
      }
    }
  };
  return (
    <div>
        { drawer ? <Drawer/> : <>
        <DocumentMeta {...meta}>
        <AboutCompOne/>
        <AboutCompTwo/>
        <AboutCompThree/>
        <div className='about-tag-line'>
          <span>To be the catalyst for change in the digital world, bridging technological advancement with market needs.</span>
        </div>
        <ContactComp/>
        <Footer/>
        </DocumentMeta>
        </>
       }
    </div>
  )
}
