import React, {useContext} from 'react'
import ContactComp from '../../../components/contactcomp/ContactComp';
import HeroSection from '../../../components/ServiceComponent/HeroSection/HeroSection';
import WhyWhat from '../../../components/ServiceComponent/WhyWhat/WhyWhat';
import Consulting from '../../../components/ServiceComponent/Consulting/Consulting';
import ServicesfourthComp from '../../../components/ServiceComponent/ServicesfourthComp/ServicesfourthComp';
import ServicefifthComp from '../../../components/ServiceComponent/ServicefifthComp/ServicefifthComp';
import ServicesEightComp from '../../../components/ServiceComponent/ServicesEightComp/ServicesEightComp';
import ServicesSixthComp from '../../../components/ServiceComponent/ServicesSixthComp/ServicesSixthComp';
import ServicesSeventhComp from '../../../components/ServiceComponent/ServicesSeventhComp/ServicesSeventhComp';
import Footer from '../../../components/footer/Footer';
import { marketingContent } from './marketing';

import { UserContext } from '../../../context/userContext';
import Drawer from '../../../components/drawer/Drawer';
import DocumentMeta from 'react-document-meta';
import { Helmet } from 'react-helmet';

export default function MarketingServicePage() {
  const {drawer} = useContext(UserContext);
  // const meta = {
  //   title: 'Social Media Agency NYC | Social Media Marketing Company in New York- Fusiontecz',
  //   description: 'Enhance your business with Fusiontecz Digital Marketing Agency in New York. Specializing in pay-per-click marketing, social media, and comprehensive digital marketing strategies. Transform your digital presence today!',
  //   canonical: 'https://www.fusiontecz.com/marketing',
  //   meta: {
  //     charset: 'utf-8',
  //     name: {
  //       keywords: 'paid advertising agency, seo agency near me, top digital marketing companies, marketing agency for startups, digital display advertising, digital marketing agency, performance marketing agencies, ecommerce marketing agencies'
  //     }
  //   }
  // };
  return (
    <div>
      { drawer ? <Drawer/> : <>
        <Helmet>
        <meta charSet="UTF-8" />
        <title>Social Media Agency NYC | Social Media Marketing Company in New York- Fusiontecz</title>
        <meta name="description" content="Enhance your business with Fusiontecz Digital Marketing Agency in New York. Specializing in pay-per-click marketing, social media, and comprehensive digital marketing strategies. Transform your digital presence today!" />
        <meta name="keywords" content="digital marketing agency for small business, paid social agency, best social media marketing agency, best digital marketing companies,digital display advertising, ppc specialist, white label digital marketing, online marketing agency, social media marketing companies, social media management companies, social media marketing agency" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://www.fusiontecz.com/marketing" />
        </Helmet>
        <HeroSection 
        title={marketingContent.firstSection.title}
        content={marketingContent.firstSection.content}/>
        <WhyWhat
        utilityContent={marketingContent.thirdSection.utility}
        usContent={marketingContent.thirdSection.us}
        />
        <Consulting
        title={marketingContent.fourthSection.title}
        subTitle={marketingContent.fourthSection.subTitle}
        parah={marketingContent.fourthSection.parah}
        items={marketingContent.fourthSection.items}
        />
        <ServicesfourthComp
        title={marketingContent.fifthSection.title}
        content={marketingContent.fifthSection.content}
        items={marketingContent.fifthSection.items}
        />
        <ServicefifthComp
        title={marketingContent.sixthSection.title}
        content={marketingContent.sixthSection.content}
        btnContent={marketingContent.sixthSection.btnContent}
        />
        <ServicesEightComp
        title={marketingContent.seventhSection.title.title}
        spanContent={marketingContent.seventhSection.title.spanContent}
        content={marketingContent.seventhSection.content}
        items={marketingContent.seventhSection.items}
        ending={marketingContent.seventhSection.ending}
        btncontent={marketingContent.seventhSection.btnContent}
        />
        {/* <ServicesSixthComp/>
        <ServicesSeventhComp/> */}
        <ContactComp/>
        <Footer/>

        </>
        }
      </div>
  )
}
