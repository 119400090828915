import usImage from "../../../assets/images/serviceImages/outsourcing/us.png";
import utilityImage from "../../../assets/images/serviceImages/outsourcing/utility.png";

import Vector from "../../../assets/icons/Vector.png";
import alertCircle from "../../../assets/icons/alert-circle-sharp.png";
import implementation from "../../../assets/icons/implementation.png";
import ucc from "../../../assets/images/serviceImages/ucc.png";
import loan from "../../../assets/images/serviceImages/loan.png";
import creditCard from "../../../assets/images/serviceImages/credit-card.png";
import credit from "../../../assets/images/serviceImages/credit.png";
import integration from "../../../assets/images/serviceImages/integration.png";

export const outsourcingContent={
    firstSection:{
        title:"Focus on Growth, Not the Grind",
        content:"As a business owner, your primary goal is to grow your business, but the demands of daily operations can often pull you away from strategic efforts. According to a recent pulse survey on time management, nearly 70% of business owners find themselves bogged down by administrative tasks and employee issues, leaving little time for driving growth and innovation."
    },
    secondSection:{

    },
    thirdSection:{
        utility:{
            title:"Scalable Solutions Tailored to Your Business Needs",
            content:"Our services are designed to adapt and scale with your business as it evolves. Whether you're a small startup or an established enterprise, we offer essential HR, tax, and accounting services packaged to fit your specific requirements. We ensure that your operational needs are met efficiently, so you can dedicate more time to strategic growth.",
            image: utilityImage,
        },
        us:{
            title:"We Solve Your Biggest Challenges Across IT and Beyond",
            content:"Fusiontecz provides top-tier professionals across IT, Fintech, Accounting, Taxation, Marketing, and more. Whether you need expert software developers or customized service plans, we deliver solutions tailored to your business needs, driving transformation and success.",
            image: usImage,
        }
    },
    fourthSection:{
        title:"Perfect Talent, Seamless Fit",
        subTitle:"Precision Talent Solutions",
        parah:"We connect you with the ideal talent, ensuring a flawless fit and smooth integration into your business. Simplify staffing and focus on growth.",
        items:[
            {
                title:"Understaing",
                image:Vector,
                about:"Your Staffing and Outsourcing Needs."
            },
            {
                title:"Matching",
                image:alertCircle,
                about:"You with the Right Talent Services."
            },
            {
                title:"Seamless",
                image:implementation,
                about:"Integration into Your Business Processes."
            }
        ]
    },
    fifthSection:{
        title:"Comprehensive B2B Support Across Categories",
        content:"We cater to businesses of all sizes, providing scalable solutions that cover every aspect of your operations.",
        items:[
            {
                title:"Temporary and Permanent Staffing",
                image:ucc,
                about:"Whether you need short-term project support or long-term team members, we provide the right talent to fit your business needs. As one of the leading staffing companies near you, we ensure that your staffing needs are met with efficiency and quality.",
                btnContent:"Improve Your Cash Flow!"
            },
            {
                title:"Project-Based Outsourcing",
                image:creditCard,
                about:"Access specialized skills for specific projects, ensuring timely delivery and high-quality outcomes without the overhead of full-time hires. Our employment agency staffing solutions are designed to match your project needs precisely.",
                btnContent:"Improve Your Cash Flow!"
            },
            {
                title:"Workforce Management Solutions",
                image:loan,
                about:"Streamline your workforce with our comprehensive management services, optimizing productivity and minimizing operational disruptions.",
                btnContent:"Improve Your Cash Flow!"
            },
            {
                title:"Accounting Experts",
                image:credit,
                about:"Hire professional accountants, underwriters, scrubbers, and financial analysts who understand your industry's financial landscape",
                btnContent:"Improve Your Cash Flow!"
            }
        ]
    },
    sixthSection:{
        title:"Expert Staffing and Outsourcing with Fusiontecz",
        content:"Leverage our experienced professionals for your staffing and outsourcing needs. We provide seasoned talent, not freshers, ensuring your projects are in capable hands. Plus, with full access to our tracking systems, you can monitor performance while focusing on growing your business.",
        btnContent:"Partner with Fusiontecz today!"
    },
    seventhSection:{
        title:{
            spanContent:"Why Partner with",
            title: "Us?"
         },
        content:"We offer several key benefits to ensure your partnership with us drives your business forward.",
        items:[
            {
                title:"Full Access to Employee Tracking",
                image:integration,
                content:"Gain complete visibility over your workforce with our advanced tracking systems. Monitor progress, productivity, and performance in real-time, ensuring that your team stays aligned with your business goals."
            },
            {
                title:"Experienced and Professional Staff",
                image:integration,
                content:"Our team consists of seasoned professionals who bring industry-specific expertise to the table. You can trust that your projects will be handled with the highest level of skill and professionalism."
            },
            {
                title:"Transparency in Operations",
                image:integration,
                content:"We pride ourselves on maintaining clear, open communication with our clients. You'll always be in the loop regarding project status, staffing details, and financials, allowing you to make informed decisions with confidence."
            },
            {
                title:"Unlimited Access to a Global Talent Pool",
                image:integration,
                content:"Tap into our vast network of qualified professionals, giving you endless options to find the perfect fit for your team."
            },
            {
                title:"Commitment to Quality",
                image:integration,
                content:"We focus on delivering high-quality staffing solutions that meet and exceed your expectations, ensuring your business runs smoothly and efficiently."
            }
        ],
        ending:"We believe that your time should be spent on growing your business, not getting tangled in the day-to-day. Let us handle the complexities of staffing, HR, and financial management, so you can focus on what truly matters—building a scalable, successful business.",
        btnContent:"Contact Us Today!!"
    },
}