import React from 'react'
import { Link } from 'react-scroll';

export default function ContactButton() {
  return (
    <div className='nav-contact-btn'>
          <Link to="footer-main" 
           spy={true} 
           smooth={true} 
           offset={50} 
           duration={500} >
          <span>Contact Us</span>
          </Link>
    </div>
  )
}
