import React, {useContext} from 'react'
import NavbarServices from "../../../components/navbar-services/NavbarServices";
import ContactComp from '../../../components/contactcomp/ContactComp';
import Footer from '../../../components/footer/Footer';
import Drawer from '../../../components/drawer/Drawer';
import { UserContext } from '../../../context/userContext';
// import DocumentMeta from 'react-document-meta';
import { Helmet } from 'react-helmet';

import HeroSection from '../../../components/ServiceComponent/HeroSection/HeroSection';
import WhyWhat from '../../../components/ServiceComponent/WhyWhat/WhyWhat';
import Consulting from '../../../components/ServiceComponent/Consulting/Consulting';
import ServicesfourthComp from '../../../components/ServiceComponent/ServicesfourthComp/ServicesfourthComp';
import ServicefifthComp from '../../../components/ServiceComponent/ServicefifthComp/ServicefifthComp';
import ServicesEightComp from '../../../components/ServiceComponent/ServicesEightComp/ServicesEightComp';
import { accountingContent } from './accounting';

export default function AccountingServicePage() {
  const {drawer} = useContext(UserContext);
   // const meta = {
  //   title: 'Top Accounting Agency Near Me - Fusiontecz',
  //   description: 'Fusiontecz offers accounting services, paid advertising, social advertising, and display ad solutions in the USA. Our expert team delivers unique strategies to enhance your online business.',
  //   canonical: 'https://www.fusiontecz.com/accountingservice',
  //   meta: {
  //     charset: 'utf-8',
  //     name: {
  //       keywords: ''
  //     }
  //   }
  // };
  
  return (
      <div>
        { drawer ? <Drawer/> : <>
          {/* <DocumentMeta {...meta}> */}

          <Helmet>
            <meta charSet="UTF-8" />
            <title>Top Accounting Agency Near Me - Fusiontecz</title>
            <meta name="description" content="Fusiontecz offers accounting services, paid advertising, social advertising, and display ad solutions in the USA. Our expert team delivers unique strategies to enhance your online business." />
            <meta name="keywords" content="" />
            <link rel="canonical" href="https://www.fusiontecz.com/accountingservice" />
          </Helmet>
          <HeroSection 
          title={accountingContent.firstSection.title}
          content={accountingContent.firstSection.content}/>
           <WhyWhat
            utilityContent={accountingContent.thirdSection.utility}
            usContent={accountingContent.thirdSection.us}
            />
          <Consulting
          title={accountingContent.fourthSection.title}
          subTitle={accountingContent.fourthSection.subTitle}
          parah={accountingContent.fourthSection.parah}
          items={accountingContent.fourthSection.items}
          />
          <ServicesfourthComp
          title={accountingContent.fifthSection.title}
          content={accountingContent.fifthSection.content}
          items={accountingContent.fifthSection.items}
          />
          <ServicefifthComp
          title={accountingContent.sixthSection.title}
          content={accountingContent.sixthSection.content}
          btnContent={accountingContent.sixthSection.btnContent}
          />
          <ServicesEightComp
          title={accountingContent.seventhSection.title.title}
          spanContent={accountingContent.seventhSection.title.spanContent}
          content={accountingContent.seventhSection.content}
          items={accountingContent.seventhSection.items}
          ending={accountingContent.seventhSection.ending}
          btncontent={accountingContent.seventhSection.btnContent}
          />
          {/* <ServicesSixthComp/>
          <ServicesSeventhComp/> */}
          <ContactComp/>
          <Footer/>
        {/* </DocumentMeta> */}
      </>
      }
    </div>
  )
}
