import React, {useContext} from 'react'
import ContactComp from '../../../components/contactcomp/ContactComp';
import HeroSection from '../../../components/ServiceComponent/HeroSection/HeroSection';
import WhyWhat from '../../../components/ServiceComponent/WhyWhat/WhyWhat';
import Consulting from '../../../components/ServiceComponent/Consulting/Consulting';
import ServicesfourthComp from '../../../components/ServiceComponent/ServicesfourthComp/ServicesfourthComp';
import ServicefifthComp from '../../../components/ServiceComponent/ServicefifthComp/ServicefifthComp';
import ServicesEightComp from '../../../components/ServiceComponent/ServicesEightComp/ServicesEightComp';
import ServicesSixthComp from '../../../components/ServiceComponent/ServicesSixthComp/ServicesSixthComp';
import ServicesSeventhComp from '../../../components/ServiceComponent/ServicesSeventhComp/ServicesSeventhComp';
import { outsourcingContent } from './outsourcing';

import Footer from '../../../components/footer/Footer';
import { UserContext } from '../../../context/userContext';
import Drawer from '../../../components/drawer/Drawer';
// import DocumentMeta from 'react-document-meta';
import { Helmet } from 'react-helmet';

export default function OutsourcingServicePage() {
  const {drawer} = useContext(UserContext);
  // const meta = {
  //   title: 'Top Outsourcing Agency Near Me - Fusiontecz',
  //   description: 'Fusiontecz offers outsourcing service, paid advertising, social advertising, and display ad solutions in the USA. Our expert team delivers unique strategies to enhance your online business.',
  //   canonical: 'https://www.fusiontecz.com/outsourcingservice',
  //   meta: {
  //     charset: 'utf-8',
  //     name: {
  //       keywords: ''
  //     }
  //   }
  // };
  return (
    <div>
      { drawer ? <Drawer/> : <>
        {/* <DocumentMeta {...meta}> */}
        <Helmet>
          <meta charSet="UTF-8" />
          <title>Top Accounting Agency Near Me - Fusiontecz</title>
          <meta name="description" content="Fusiontecz offers outsourcing service, paid advertising, social advertising, and display ad solutions in the USA. Our expert team delivers unique strategies to enhance your online business." />
          <meta name="keywords" content="" />
          <link rel="canonical" href="https://www.fusiontecz.com/outsourcingservice" />
        </Helmet>
        <HeroSection 
        title={outsourcingContent.firstSection.title}
        content={outsourcingContent.firstSection.content}/>
         <WhyWhat
          utilityContent={outsourcingContent.thirdSection.utility}
          usContent={outsourcingContent.thirdSection.us}
          />
        <Consulting
        title={outsourcingContent.fourthSection.title}
        subTitle={outsourcingContent.fourthSection.subTitle}
        parah={outsourcingContent.fourthSection.parah}
        items={outsourcingContent.fourthSection.items}
        />
        <ServicesfourthComp
        title={outsourcingContent.fifthSection.title}
        content={outsourcingContent.fifthSection.content}
        items={outsourcingContent.fifthSection.items}
        />
        <ServicefifthComp
        title={outsourcingContent.sixthSection.title}
        content={outsourcingContent.sixthSection.content}
        btnContent={outsourcingContent.sixthSection.btnContent}
        />
        <ServicesEightComp
        title={outsourcingContent.seventhSection.title.title}
        spanContent={outsourcingContent.seventhSection.title.spanContent}
        content={outsourcingContent.seventhSection.content}
        items={outsourcingContent.seventhSection.items}
        ending={outsourcingContent.seventhSection.ending}
        btncontent={outsourcingContent.seventhSection.btnContent}
        />
        {/* <ServicesSixthComp/>
        <ServicesSeventhComp/> */}
        <ContactComp/>
        <Footer/>
        {/* </DocumentMeta> */}
        </>
       }
      </div>
  )
}
