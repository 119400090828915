import React, { useContext } from 'react'
import LandingCompOne from '../../components/landingcomp/landingcompone/LandingCompOne'
import LandingCompTwo from '../../components/landingcomp/landingcomptwo/LandingCompTwo'
import LandingCompThree from "../../components/landingcomp/landingcompthree/LandingCompThree"
import LandingCompFour from '../../components/landingcomp/landingcompfour/LandingCompFour'
import LandingCompFive from '../../components/landingcomp/landingcompfive/LandingCompFive'
import LandingCompSix from '../../components/landingcomp/landingcompsix/LandingCompSix'
import Footer from '../../components/footer/Footer'
import LandingCompSeven from '../../components/landingcomp/landingcompseven/LandingCompSeven'
import ReviewComp from '../../components/reviewcomp/ReviewComp'
import ContactComp from '../../components/contactcomp/ContactComp'
import Drawer from '../../components/drawer/Drawer'
import { UserContext } from '../../context/userContext'
export default function LandingPage() {
  const {drawer} = useContext(UserContext);
  return (
    <div>
      { drawer ? <Drawer/> : <>
      <LandingCompOne/>
      <LandingCompTwo/>
      <LandingCompThree/>
      <LandingCompFour/>
      <LandingCompFive/>
      <LandingCompSix/>
      <LandingCompSeven/>
      <ReviewComp/>
      <ContactComp/>
      <Footer/>
      </>
      }
    </div>
  )
}
