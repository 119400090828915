import React from 'react'
import { Routes, Route, Navigate } from "react-router-dom";
import LandingPage from '../pages/landingpage/LandingPage';
import AboutPage from '../pages/aboutpage/AboutPage';
import HowItWorksPage from '../pages/howitworkspage/HowItWorksPage';
import ITServicePage from '../pages/services/ITService/ITServicePage';
import FintechServicePage from '../pages/services/FintechService/FintechServicePage';
import MarketingServicePage from '../pages/services/MarketingService/MarketingServicePage';
import OutsourcingServicePage from "../pages/services/OutsourcingService/OutsourcingServicePage";
import AccountingServicePage from "../pages/services/AccountingService/AccountingServicePage";
import ThankYouPage from '../pages/thankyoupage/ThankYouPage';
export default function Router() {
  return (
    <Routes>
          <Route
            exact
            path="/"
            element={
              <LandingPage/>
          }
          />
          <Route
            exact
            path="/about"
            element={
              <AboutPage/>
          }
          />
          <Route
            exact
            path="/howitworks"
            element={
              <HowItWorksPage/>
          }
          />
          <Route
          exact
          path='/services'
          element={
            <ITServicePage/>
          }
          />
          <Route
            exact
            path="/itservice"
            element={
              <ITServicePage/>
          }
          />
          <Route
            exact
            path="/fintechservice"
            element={
              <FintechServicePage/>
          }
          />
          <Route
            exact
            path="/marketingservice"
            element={<Navigate to="/marketing" replace />}
          />
           <Route
            exact
            path="/marketing"
            element={
              <MarketingServicePage/>
          }
          />
           <Route
            exact
            path="/outsourcingservice"
            element={
              <OutsourcingServicePage/>
          }
          />
          <Route
            exact
            path="/accountingservice"
            element={
              <AccountingServicePage/>
          }
          />
             <Route
            exact
            path="/thankyou"
            element={
              <ThankYouPage/>
          }
          />
           <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  )
}
