import usImage from "../../../assets/images/serviceImages/marketing/us.png";
import utilityImage from "../../../assets/images/serviceImages/marketing/utility.png";

import Vector from "../../../assets/icons/Vector.png";
import alertCircle from "../../../assets/icons/alert-circle-sharp.png";
import implementation from "../../../assets/icons/implementation.png";
import ucc from "../../../assets/images/serviceImages/ucc.png";
import loan from "../../../assets/images/serviceImages/loan.png";
import creditCard from "../../../assets/images/serviceImages/credit-card.png";
import credit from "../../../assets/images/serviceImages/credit.png";
import integration from "../../../assets/images/serviceImages/integration.png";

export const marketingContent={
    firstSection:{
        title:"Your Brand’s Path to Success",
        content:"At Fusiontecz, a leading social media marketing firm in Toronto, we don’t just create social media plans—we build brands. With our innovative strategies and creative planning, we ensure your brand not only stands out but also grows. Trust us to craft a powerful online presence that connects with your audience and drives real results. Fusiontecz is where your brand’s journey to success begins."
    },
    secondSection:{

    },
    thirdSection:{
        utility:{
            title:"Your Partner in Digital Marketing Excellence",
            content:"Fusiontecz drives your business forward with expertly crafted digital marketing strategies. We excel at building community-focused brands and ensuring proactive reputation management, so your business not only stands out but thrives.",
            image: utilityImage,
        },
        us:{
            title:"Strengthen Your Brand’s Presence",
            content:"Through targeted campaigns, we enhance your visibility, attract more customers, and cultivate a positive, engaging image that resonates with your audience. Partner with Fusiontecz to transform your digital impact.",
            image: usImage,
        }
    },
    fourthSection:{
        title:"Strategic Branding in Action",
        subTitle:"At Fusiontecz, a top digital marketing agency in Toronto, we follow a proven process to elevate your brand on social media. From planning to execution, every step is designed to maximize your brand’s impact and drive growth.",
        parah:"With Fusiontecz, your brand’s growth is always strategically planned and expertly executed, making us one of the best social media marketing companies in Toronto.",
        items:[
            {
                title:"Plan",
                image:Vector,
                about:"We start by understanding your goals and audience to create a customized roadmap."
            },
            {
                title:"Strategize",
                image:alertCircle,
                about:"Next, we develop creative strategies tailored to your brand’s vision."
            },
            {
                title:"Execute",
                image:implementation,
                about:"Our team brings these strategies to life with engaging content and targeted campaigns."
            }
        ]
    },
    fifthSection:{
        title:"Comprehensive Digital Marketing Services",
        content:"Beyond social media, Fusiontecz offers a full suite of digital marketing services in Toronto designed to elevate every aspect of your brand’s online presence. We specialize in",
        items:[
            {
                title:"SEO Marketing",
                image:loan,
                about:"Our customized SEO strategies focus on boosting your search engine rankings, drawing in more organic traffic, and expanding your brand's online visibility.",
                btnContent:"Improve Your Cash Flow!"
            },
            {
                title:"Social Media Management",
                image:creditCard,
                about:"Strengthen your brand on platforms like Instagram, Facebook, TikTok, Twitter, and LinkedIn for strategic content and campaigns.",
                btnContent:"Improve Your Cash Flow!"
            },
            {
                title:"PPC Management",
                image:ucc,
                about:"Achieve your target audience with precision through pay-per-click campaigns that deliver immediate results and maximize your ROI.",
                btnContent:"Improve Your Cash Flow!"
            },
            {
                title:"Graphics & Video Editing",
                image:credit,
                about:"your brand’s visual appeal with professional graphics and video editing that captivate your audience and reinforce your brand’s identity.",
                btnContent:"Improve Your Cash Flow!"
            }
        ]
    },
    sixthSection:{
        title:"Amplify Your Brand's Reach",
        content:"At Fusiontecz, we enhance your brand's visibility through strategic social media marketing, compelling content creation, and effective SEO optimization. You don’t need to be tech-savvy to use our services—we make the process simple and streamlined, handling the complexities so you can focus on growing your business. Partner with us to achieve lasting success in the digital landscape.",
        btnContent:"Consult Now!"
    },
    seventhSection:{
        title:{
            spanContent:"Your All-in-One",
            title: "Social Media Marketing Agency in Toronto"
         },
        content:"At Fusiontecz, a premier social media marketing agency in Toronto, we’re dedicated to making your brand stand out across every platform. Here’s how our social media services can benefit your business.",
        items:[
            {
                title:"Instagram Ads",
                image:integration,
                content:"Capture attention with visually stunning ads that target the right audience, driving engagement and conversions."
            },
            {
                title:"Facebook Campaigns",
                image:integration,
                content:"Leverage Facebook’s massive reach to connect with diverse audiences, boosting brand loyalty and awareness."
            },
            {
                title:"TikTok Content Creation",
                image:integration,
                content:"Stay ahead of trends with viral-worthy videos that resonate with younger, dynamic audiences."
            },
            {
                title:"Twitter Engagement",
                image:integration,
                content:"Build a strong brand voice and foster real-time connections with followers, enhancing your brand’s online presence."
            },
            {
                title:"LinkedIn Networking",
                image:integration,
                content:"Establish your brand as a thought leader, connecting with industry professionals and expanding your influence."
            },
            {
                title:"Paid Social Services",
                image:integration,
                content:"Maximize your marketing efforts with targeted ads that increase brand visibility and drive measurable results."
            }
        ],
        ending:"Ready to elevate your brand with the best social media marketing services in Toronto? Partner with Fusiontecz, the top SEO consultant in Toronto and a leading SEO agency in Toronto, and let’s transform your social media presence into a powerful marketing tool.",
        btnContent:"Contact us today!"
    },
}