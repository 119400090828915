import React, { useContext, useState } from 'react'
import "./NavbarServices.css"
// import Logo from "../../assets/icons/BlackLogo.png";
import Logo from "../../assets/icons/Logo.png";
// import BlackMenu from "../../assets/icons/black-menu.svg";
import { FaBars } from "react-icons/fa6";
import { useNavigate, Link } from "react-router-dom";
import { UserContext } from '../../context/userContext';
import ContactButton from '../contactbutton/ContactButton';
import ServiceContactButton from '../contactbutton/ServiceContactButton';

export default function NavbarServices() {
  const {setDrawer} = useContext(UserContext)
  const navigate = useNavigate();
  const [showDropdown, setShowDropdown] = useState(false);

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const closeDropdown = () => {
    setShowDropdown(false);
  };

  return (
    <div className='navbar-services-main'>
      <div className='navbar-services-logo-cont'>
        <Link to='/' className='navbar-services-logo-cont'>
        <img src={Logo} className='logo-img' alt='img'/>
        <span 
          style={{color:"#FF7B91", fontStyle: "normal"}}
        >where innovation meets excellence</span>
        </Link>
      </div>
      <div className='navbar-services-links'>
        <span className='navbar-services-links-span'><Link to="/about" className='navbar-services-links-span'>About Us</Link></span>
        <span className='navbar-services-links-span' onClick={()=>toggleDropdown()}>Services</span>
        {showDropdown && (
            <div className="dropdown-services-content">
              <span className='dropdown-content-links'><Link to='/itservice' className='dropdown-content-links'>IT Consulting And Development</Link></span>
              <span className='dropdown-content-links'><Link to='/outsourcingservice' className='dropdown-content-links'>Outsourcing Services</Link></span>
              <span className='dropdown-content-links'><Link to='/fintechservice' className='dropdown-content-links'>Fintech</Link></span>
              <span className='dropdown-content-links'><Link to='/marketing' className='dropdown-content-links'>Digital Marketing</Link></span>
              <span className='dropdown-content-links'><Link to='/accountingservice' className='dropdown-content-links'>Accounting And Bookkeeping</Link></span>
              {/* Add more services as needed */}
            </div>
          )}
        <span className='navbar-services-links-span'><Link to='/howitworks' className='navbar-services-links-span'>How it works</Link></span>
        {/* <span className='navbar-services-links-span'>Blogs</span> */}
        {/* <div className='navbar-services-contact-btn'>
        <Link to="footer-main" 
      spy={true} 
      smooth={true} 
      offset={50} 
      duration={500} > <span>Contact Us</span>
      </Link>
        </div> */}
        <ServiceContactButton/>
        <FaBars  className='menu-bar' onClick={()=>setDrawer(true)}/>
        {/* <img src={BlackMenu} className='menu-bar' onClick={()=>setDrawer(true)}/> */}
      </div>
      {showDropdown && <div className="overlay" onClick={closeDropdown}></div>}
    </div>
  )
}
