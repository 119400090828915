import usImage from "../../../assets/images/serviceImages/accounting/us.png";
import utilityImage from "../../../assets/images/serviceImages/accounting/utility.png";

import Vector from "../../../assets/icons/Vector.png";
import alertCircle from "../../../assets/icons/alert-circle-sharp.png";
import implementation from "../../../assets/icons/implementation.png";
import ucc from "../../../assets/images/serviceImages/ucc.png";
import loan from "../../../assets/images/serviceImages/loan.png";
import creditCard from "../../../assets/images/serviceImages/credit-card.png";
import credit from "../../../assets/images/serviceImages/credit.png";
import integration from "../../../assets/images/serviceImages/integration.png";

export const accountingContent={
    firstSection:{
        title:"Your Financial Path to Success",
        content:"At Fusiontecz, we go beyond managing numbers—we empower your business to thrive. With our precision-focused accounting and bookkeeping services, we ensure your financial health is strong and sustainable. Trust us to handle your financial needs with accuracy and care, so you can concentrate on driving your business forward. Fusiontecz is where your journey to financial success begins."
    },
    secondSection:{

    },
    thirdSection:{
        utility:{
            title:"Your Partner in Financial Management Excellence",
            content:"Fusiontecz accelerates your business’s growth with expertly managed accounting and bookkeeping services. We excel in maintaining accurate records, offering insightful financial analysis, and ensuring compliance, so your business not only stays on track but flourishes.",
            image: utilityImage,
        },
        us:{
            title:"Enhance Your Financial Stability",
            content:"Our comprehensive services provide you with a clear and accurate view of your finances, enabling better decision-making. Partner with Fusiontecz to secure a solid financial foundation and achieve lasting growth.",
            image: usImage,
        }
    },
    fourthSection:{
        title:"Strategic Financial Management in Action",
        subTitle:"At Fusiontecz, we follow a proven process to elevate your business’s financial health. Every step, from planning to execution, is meticulously designed to maximize your financial stability and drive success.",
        parah:"With Fusiontecz, your financial growth is always strategically planned and expertly managed, making us a trusted partner in your financial journey.",
        items:[
            {
                title:"Assess",
                image:Vector,
                about:"We begin by understanding your business’s financial needs and goals to craft a customized financial strategy."
            },
            {
                title:"Strategize",
                image:alertCircle,
                about:"We then develop tailored strategies to optimize your financial processes and ensure full compliance."
            },
            {
                title:"Execute",
                image:implementation,
                about:"Our team implements these strategies with precision, delivering accurate records and valuable financial insights."
            }
        ]
    },
    fifthSection:{
        title:"Comprehensive Financial Management Services",
        content:"Fusiontecz offers a full suite of accounting and bookkeeping services designed to enhance every aspect of your business’s financial management. Our expertise includes",
        items:[
            {
                title:"Bookkeeping Services",
                image:ucc,
                about:"Maintain clear, accurate financial records that support informed business decisions.",
                btnContent:"Improve Your Cash Flow!"
            },
            {
                title:"Tax Preparation & Planning",
                image:loan,
                about:"Minimize tax liabilities with expert planning and ensure compliance with timely, accurate filings.",
                btnContent:"Improve Your Cash Flow!"
            },
            {
                title:"Payroll Management",
                image:creditCard,
                about:"Streamline payroll processes, ensuring timely payments and adherence to regulations.",
                btnContent:"Improve Your Cash Flow!"
            },
            {
                title:"Financial Reporting",
                image:credit,
                about:"Access detailed financial reports that offer valuable insights into your business’s performance.",
                btnContent:"Improve Your Cash Flow!"
            }
        ]
    },
    sixthSection:{
        title:"Secure Your Financial Future",
        content:"At Fusiontecz, we strengthen your business’s financial foundation through expert accounting and bookkeeping services. We make the process simple and stress-free, allowing you to focus on growing your business while we handle the complexities. Partner with us to achieve enduring financial success.",
        btnContent:"Consult Now!"
    },
    seventhSection:{
        title:{
            spanContent:"Your All-in-One",
            title: "Accounting and Bookkeeping Partner"
         },
        content:"At Fusiontecz, we are dedicated to safeguarding your business’s financial health. Our services are designed to support your growth across all financial aspects",
        items:[
            {
                title:"Accurate Bookkeeping",
                image:integration,
                content:"Keep your financial records precise and reliable for better decision-making."
            },
            {
                title:"Efficient Payroll Management",
                image:integration,
                content:"Ensure your employees are paid accurately and on time, with full compliance."
            },
            {
                title:"Tax Optimization & Compliance",
                image:integration,
                content:"Optimize your tax strategy and ensure timely, accurate filings."
            },
            {
                title:"Insightful Financial Reporting",
                image:integration,
                content:"Gain clarity with detailed financial reports that inform your business strategy."
            },
            {
                title:"Strategic Budgeting & Forecasting",
                image:integration,
                content:"Plan for sustainable growth with tailored budgeting and forecasting services."
            },
            {
                title:"Custom Financial Solutions",
                image:integration,
                content:"Whether you need comprehensive accounting support or specific financial guidance, we provide solutions that fit your unique needs."
            }
        ],
        ending:"Ready to strengthen your financial foundation with expert accounting and bookkeeping services? Partner with Fusiontecz and transform your financial management into a powerful driver of business growth.",
        btnContent:"Contact us today!"
    },
};