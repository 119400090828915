import React, { createContext, useReducer } from 'react';
import {
    setDRAWER
} from './types';

// Reducers
import { userReducer } from './reducers';
// Global User Context
export const UserContext = createContext();

const initialState = {
    drawer: false
};

// Global User Provider
export const UserProvider = ({ children }) => {
    const [state, dispatch] = useReducer(userReducer, initialState);

    const setDrawer = (drawer) => {
        dispatch({ type: setDRAWER, payload: drawer });
    };

    return (
        <UserContext.Provider
            value={{
                drawer: state.drawer,
                setDrawer
            }}>
            {children}
        </ UserContext.Provider>
    );
};