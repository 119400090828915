import React, {useContext} from 'react'
import ContactComp from '../../../components/contactcomp/ContactComp';
import Footer from '../../../components/footer/Footer';

import { UserContext } from '../../../context/userContext';
// import DocumentMeta from 'react-document-meta';

import { Helmet } from 'react-helmet';
import HeroSection from '../../../components/ServiceComponent/HeroSection/HeroSection';
import WhyWhat from '../../../components/ServiceComponent/WhyWhat/WhyWhat';
import Consulting from '../../../components/ServiceComponent/Consulting/Consulting';
import ServicesfourthComp from '../../../components/ServiceComponent/ServicesfourthComp/ServicesfourthComp';
import ServicefifthComp from '../../../components/ServiceComponent/ServicefifthComp/ServicefifthComp';
import ServicesEightComp from '../../../components/ServiceComponent/ServicesEightComp/ServicesEightComp';
import ServicesSixthComp from '../../../components/ServiceComponent/ServicesSixthComp/ServicesSixthComp';
import ServicesSeventhComp from '../../../components/ServiceComponent/ServicesSeventhComp/ServicesSeventhComp';
import { fintechContent } from './fintech';

import Drawer from '../../../components/drawer/Drawer'; 

export default function FintechServicePage() {
  const {drawer} = useContext(UserContext);
  // const meta = {
  //   title: 'Top Fintech Agency Near Me - Fusiontecz',
  //   description: 'Fusiontecz offers fintech services, paid advertising, social advertising, and display ad solutions in the USA. Our expert team delivers unique strategies to enhance your online business.',
  //   canonical: 'https://www.fusiontecz.com/fintechservice',
  //   meta: {
  //     charset: 'utf-8',
  //     name: {
  //       keywords: ''
  //     }
  //   }
  // };
  return (
    <div>
      { drawer ? <Drawer/> : <>
        {/* <DocumentMeta {...meta}> */}

        <Helmet>
            <meta charSet="UTF-8" />
            <title>Top Fintech Agency Near Me - Fusiontecz</title>
            <meta name="description" content="Fusiontecz offers fintech services, paid advertising, social advertising, and display ad solutions in the USA. Our expert team delivers unique strategies to enhance your online business." />
            <meta name="keywords" content="" />
            <meta name="robots" content="index, follow" />
            <link rel="canonical" href="https://www.fusiontecz.com/fintechservice" />
        </Helmet> 
        <HeroSection 
        title={fintechContent.firstSection.title}
        content={fintechContent.firstSection.content}/>
        <WhyWhat
        utilityContent={fintechContent.thirdSection.utility}
        usContent={fintechContent.thirdSection.us}
        />
        <Consulting
        title={fintechContent.fourthSection.title}
        subTitle={fintechContent.fourthSection.subTitle}
        parah={fintechContent.fourthSection.parah}
        items={fintechContent.fourthSection.items}
        />
        <ServicesfourthComp
        title={fintechContent.fifthSection.title}
        content={fintechContent.fifthSection.content}
        items={fintechContent.fifthSection.items}
        />
        <ServicefifthComp
        title={fintechContent.sixthSection.title}
        content={fintechContent.sixthSection.content}
        btnContent={fintechContent.sixthSection.btnContent}
        />
        <ServicesEightComp
        title={fintechContent.seventhSection.title.title}
        spanContent={fintechContent.seventhSection.title.spanContent}
        content={fintechContent.seventhSection.content}
        items={fintechContent.seventhSection.items}
        ending={fintechContent.seventhSection.ending}
        btncontent={fintechContent.seventhSection.btnContent}
        />
        {/* <ServicesSixthComp/>
        <ServicesSeventhComp/> */}
        <ContactComp/>
        <Footer/>
        {/* </DocumentMeta> */}
    </>
    }
  </div>
  )
}
