import React from 'react'
import "./LandingCompOne.css"
import Navbar from '../../navbar/Navbar'
import WhiteArrow from "../../../assets/icons/WhiteArrow.png"
import { Link } from 'react-scroll'

export default function LandingCompOne() {
  return (
    <div className='landing-main1'>
      <Navbar/>
      <div className='landing-main1-cont'>
      <span className='landing-main1-tag'>Where Excellence Meets Evolution</span>
      <span className='landing-main1-bio'>Step into a world of possibilities with Fusiontecz. Our services are the catalysts for your success, unlocking unparalleled value in your business.</span>
      <div className='landing-main1-button'>
      <Link to="contact-comp" 
            spy={true} 
            smooth={true} 
            offset={50} 
            duration={500}>
        <span>Drive Success – Reach Fusiontecz Today</span>
        </Link>
      </div>
      <div>
        <img src={WhiteArrow} className='landing-main1-arrow' alt='img'/>
      </div>
      </div>
    </div>
  )
}
