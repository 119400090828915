import React from "react";
import "../Consulting/consulting.css";
import "../services/services.css";
import Vector from "../../../assets/icons/Vector.png";
import alertCircleSharp  from "../../../assets/icons/alert-circle-sharp.png";
import implementation from "../../../assets/icons/implementation.png";
import { AnimationOnScroll } from 'react-animation-on-scroll';

export default function Consulting(props){
    return(
        <>
            <AnimationOnScroll 
            animatePreScroll={false}
            duration={2} 
            initiallyVisible={false}
            animateIn="animate__fadeInUp">
                <div className="consulting-container">
                    <div className="consulting-header">
                        <div>
                            <h2>{props.title}</h2>
                            <p>
                                {props.subTitle} 
                            </p>
                        </div>
                        <span>
                        {props.parah}
                        </span>
                    </div>
                    <div className="consulting-boxes">
                        {
                            props.items.map((item, index)=>{
                                return(
                                    <>
                                        <AnimationOnScroll 
                                        animatePreScroll={false}
                                        duration={2} 
                                        initiallyVisible={false}
                                        animateIn="animate__fadeInUp" key={index}>
                                            <div className="consulting-box">
                                                <h6>
                                                    {item.title}
                                                </h6>
                                                <img src={item.image} alt="icon" className="consulting-icon" />
                                                <p>
                                                    {item.about}
                                                </p>
                                            </div>
                                        </AnimationOnScroll>
                                    </>
                                );
                            })
                        }
                    </div>
                </div>
            </AnimationOnScroll>
        </>
    );
}