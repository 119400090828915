import {
    setDRAWER
} from './types';

const setDrawer = (drawer, state) => {
    return {
        ...state,
        drawer
    };
};

export const userReducer = (state, action) => {
    switch (action.type) {
        case setDRAWER:
            return setDrawer(action.payload, state);
        default:
            return state;
    }
};