import React from "react";
import "../ServicesfourthComp/servicesfourth.css";
import "../services/services.css";
import credit from "../../../assets/images/serviceImages/credit.png";
import ucc from "../../../assets/images/serviceImages/ucc.png";
import creditCard from "../../../assets/images/serviceImages/credit-card.png";
import loan from "../../../assets/images/serviceImages/loan.png";
import { AnimationOnScroll } from 'react-animation-on-scroll';


export default function ServicesfourthComp(props){
    return(
        <>
            <AnimationOnScroll 
            animatePreScroll={false}
            duration={2} 
            initiallyVisible={false}
            animateIn="animate__fadeInUp">
                <div className="servicesfourth-container">
                    <AnimationOnScroll 
                    animatePreScroll={false}
                    duration={2} 
                    initiallyVisible={false}
                    animateIn="animate__fadeInUp">
                        <div className="serviceFourth-content">
                            <h3 className="serviceFourth-title">
                            {props.title}
                            </h3>
                            <p className="serviceFourth-parah">
                            {props.content}
                            </p>
                        </div>
                    </AnimationOnScroll>
                    <div className="serviceFourth-boxes">
                        {
                            props.items.map((item, index)=>{
                                return(
                                    <>
                                        <AnimationOnScroll 
                                        animatePreScroll={false}
                                        duration={2} 
                                        initiallyVisible={false}
                                        animateIn="animate__fadeInUp"  key={index}>
                                        <div className="serviceFourth-box">
                                            <img src={item.image} alt="image" />
                                            <div className="serviceFourth-about">
                                                <h6>
                                                    {item.title}
                                                </h6>
                                                <p>
                                                    {item.about}
                                                </p>
                                                <button className="explore-btn">{item.btnContent}</button>
                                            </div>
                                        </div>
                                        </AnimationOnScroll>
                                    </>
                                );
                            })
                        }
                    </div>
                </div>
            </AnimationOnScroll>
        </>
    );
}