import React, { useContext, useState } from "react";
import "./Drawer.css";
import Xmark from "../../assets/icons/xmark.png";
import { UserContext } from "../../context/userContext";
import { useNavigate, Link } from "react-router-dom";

export default function Drawer() {
  const { setDrawer } = useContext(UserContext);
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  return (<div className="drawer-main">
    <div onClick={() => setDrawer(false)} className="cross-wrapper">
      <img src={Xmark} className="cross" />
    </div>
    <div className="drawer-options">
      <div
        onClick={() => {
          setDrawer(false);
        }}
        className="drawer-option-wrapper"
      >
        <span><Link to='/' className="drawer-option-wrapper-links">Home</Link></span>
      </div>
      <div
        onClick={() => {
          setDrawer(false);
        }}
        className="drawer-option-wrapper"
      >
        <span><Link to='/about' className="drawer-option-wrapper-links">About Us</Link></span>
      </div>
      <div
        onClick={() => {
          setVisible(!visible)
        }}
        className="drawer-option-wrapper"
      >
        <span className="drawer-option-wrapper-links">Services</span>
      </div>
      {
         visible && (<div>
          <div
        onClick={() => {
          setDrawer(false);
        }}
        className="drawer-sub-option-wrapper"
      >
        <span><Link to='/itservice' className="drawer-sub-option-wrapper-links">IT Consulting and Development</Link></span>
      </div>
      <div
        onClick={() => {
          setDrawer(false);
        }}
        className="drawer-sub-option-wrapper"
      >
        <span><Link to='/outsourcingservice' className="drawer-sub-option-wrapper-links">Outsourcing services</Link></span>
      </div>
      <div
        onClick={() => {
          setDrawer(false);
        }}
        className="drawer-sub-option-wrapper"
      >
        <span><Link to='/fintechservice' className="drawer-sub-option-wrapper-links">Fintech</Link></span>
      </div>
      <div
        onClick={() => {
          setDrawer(false);
        }}
        className="drawer-sub-option-wrapper"
      >
        <span><Link to='/marketing' className="drawer-sub-option-wrapper-links">Digital marketing</Link></span>
      </div>
      <div
        onClick={() => {
          setDrawer(false);
        }}
        className="drawer-sub-option-wrapper"
      >
        <span><Link to='/accountingservice' className="drawer-sub-option-wrapper-links">Accounting and Bookkeeping</Link></span>
      </div>
         </div>)
      }
      <div
        onClick={() => {
          setDrawer(false);
        }}
        className="drawer-option-wrapper"
      >
        <span><Link to='/howitworks' className="drawer-option-wrapper-links">How It Works</Link></span>
      </div>
    </div>
  </div>
  );
}
