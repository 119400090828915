import React, { useEffect } from 'react'
import './ThankYouPage.css'
import GoogleAnalytics from '../../utils/GoogleAnalytics';
import Footer from "../../components/footer/Footer";
import Navbar from "../../components/navbar-services/NavbarServices"
export default function ThankYouPage() {
    const trackingId = 'AW-16605708602';
      return (

        <div>
          <Navbar/>
        <div className="thank-you-container">
         <GoogleAnalytics trackingId={trackingId} />
          <div className="background-animation"></div>
          <div className="thank-you-card">
            <h1>Thank You!</h1>
            <p>Your submission has been received successfully.</p>
            <button onClick={() => window.location.href = '/'}>Go to Homepage</button>
          </div>
        </div>
         <Footer/>
         </div>
    );
};
