import React,{useContext} from 'react'
import ContactComp from '../../../components/contactcomp/ContactComp';
import HeroSection from '../../../components/ServiceComponent/HeroSection/HeroSection';
import WhyWhat from '../../../components/ServiceComponent/WhyWhat/WhyWhat';
import Consulting from '../../../components/ServiceComponent/Consulting/Consulting';
import ServicesfourthComp from '../../../components/ServiceComponent/ServicesfourthComp/ServicesfourthComp';
import ServicefifthComp from '../../../components/ServiceComponent/ServicefifthComp/ServicefifthComp';
import ServicesEightComp from '../../../components/ServiceComponent/ServicesEightComp/ServicesEightComp';
import ServicesSixthComp from '../../../components/ServiceComponent/ServicesSixthComp/ServicesSixthComp';
import ServicesSeventhComp from '../../../components/ServiceComponent/ServicesSeventhComp/ServicesSeventhComp';
import Footer from '../../../components/footer/Footer';
import { itContent } from './it';

import Drawer from '../../../components/drawer/Drawer';
import { UserContext } from '../../../context/userContext';
// import DocumentMeta from 'react-document-meta';
import { Helmet } from 'react-helmet';

export default function ITServicePage() {
  const {drawer} = useContext(UserContext);
  // const meta = {
  //   title: 'Top Web Development Agency for Web App Development | Fusiontecz',
  //   description: 'Fusiontecz is a top web development agency offering web app development and website design services. Find the best ecommerce website developer and web design companies near you.',
  //   canonical: 'https://www.fusiontecz.com/itservice',
  //   meta: {
  //     charset: 'utf-8',
  //     name: {
  //       keywords: 'web development services, web development agency, web app development, web design companies near me, ecommerce website developer, website development services'
  //     }
  //   }
  // };
  
  return (
    <div>
      { drawer ? <Drawer/> : <>
      {/* <DocumentMeta {...meta}> */}
      <Helmet>
        <meta charSet="UTF-8" />
        <title>Top Web Development Agency for Web App Development | Fusiontecz</title>
        <meta name="description" content="Fusiontecz is a top web development agency offering web app development and website design services. Find the best ecommerce website developer and web design companies near you." />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://www.fusiontecz.com/itservice" />
      </Helmet> 
      <HeroSection 
      title={itContent.firstSection.title}
      content={itContent.firstSection.content}/>
      <WhyWhat
      utilityContent={itContent.thirdSection.utility}
      usContent={itContent.thirdSection.us}
      />
      <Consulting
      title={itContent.fourthSection.title}
      subTitle={itContent.fourthSection.subTitle}
      parah={itContent.fourthSection.parah}
      items={itContent.fourthSection.items}
      />
      <ServicesfourthComp
      title={itContent.fifthSection.title}
      content={itContent.fifthSection.content}
      items={itContent.fifthSection.items}
      />
      <ServicefifthComp
      title={itContent.sixthSection.title}
      content={itContent.sixthSection.content}
      btnContent={itContent.sixthSection.btnContent}
      />
      <ServicesEightComp
      title={itContent.seventhSection.title.title}
      spanContent={itContent.seventhSection.title.spanContent}
      content={itContent.seventhSection.content}
      items={itContent.seventhSection.items}
      ending={itContent.seventhSection.ending}
      btncontent={itContent.seventhSection.btnContent}
      />
      {/* <ServicesSixthComp/>
      <ServicesSeventhComp/> */}
      <ContactComp/>
      <Footer/>
      {/* </DocumentMeta> */}
      </>
      }
    </div>
  )
}
