import React from 'react'
import "./LandingCompTwo.css"
import { AnimationOnScroll } from 'react-animation-on-scroll'

export default function LandingCompTwo() {
  return (
    <AnimationOnScroll 
    animatePreScroll={false}
    duration={1.5} 
    initiallyVisible={false}
    animateIn="animate__fadeInUp">
    <div className='landing-main2'>
        <span className='landing-main2-head'>Why Choose Fusiontecz and How We Excel</span>
        <span className='landing-main2-tag'>Elevating Your <span className='landing-main2-tag-ext'>Success, Every Step of the Way</span></span>
        <span className='landing-main2-bio'>At Fusiontecz, we are dedicated to going above and beyond to ensure your success. Here's why we're the preferred choice and how we consistently deliver outstanding results:</span>
    </div>
    </AnimationOnScroll>
  )
}
