import React, { useState } from "react";
import "../ServicesEightComp/servicesEight.css";
import "../services/services.css";
import integration from "../../../assets/images/serviceImages/integration.png";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { GoDotFill } from "react-icons/go";

export default function ServicesEightComp(props) {

    const [currentIndex, setCurrentIndex] = useState(1);

    const handleChange = (index) => {
        setCurrentIndex(index);
    };

    return (
        <>
            <AnimationOnScroll
                animatePreScroll={false}
                duration={2}
                initiallyVisible={false}
                animateIn="animate__fadeInUp"
            >
                <div className="servicesEight-container">
                    <AnimationOnScroll
                        animatePreScroll={false}
                        duration={2}
                        initiallyVisible={false}
                        animateIn="animate__fadeInUp"
                    >
                        <div className="servicesEight-header">
                            <div className="servicesEight-title">
                                <h3><span>{props.spanContent}</span> {props.title}</h3>
                            </div>
                            <p className="servicesEight-parah">
                                {props.content}
                            </p>
                        </div>
                    </AnimationOnScroll>
                    <AnimationOnScroll
                        animatePreScroll={false}
                        duration={2}
                        initiallyVisible={false}
                        animateIn="animate__fadeInUp"
                    >
                        <div className="servicesEight-boxes-container">
                            <Carousel
                                selectedItem={currentIndex} 
                                onChange={handleChange}  
                                showThumbs={false}
                                showIndicators={false}
                                infiniteLoop={true}
                                autoPlay={true}
                                interval={2000} 
                                className='servicesEight-boxes'
                            >
                                {props.items.map((item, index) => (
                                    <div key={index} className="servicesEight-box">
                                        <div className="servicesEight-content">
                                            <h5>{item.title}</h5>
                                            <p>{item.content}</p>
                                        </div>
                                        <img src={item.image} alt={item.title} />
                                    </div>
                                ))}
                            </Carousel>
                            <div className="navigation">
                                {props.items.map((item, index) => (
                                    <span
                                        className={`nav-dot ${index === currentIndex ? 'current' : ''}`}
                                        key={index}
                                        onClick={() => setCurrentIndex(index)}  
                                    >
                                        <GoDotFill className="nav-inside" />
                                    </span>
                                ))}
                            </div>
                            <AnimationOnScroll
                            animatePreScroll={false}
                            duration={2}
                            initiallyVisible={false}
                            animateIn="animate__fadeInUp"
                            >
                                <div className="ending-container">
                                    <p>{props.ending}</p>
                                    <button>{props.btncontent}</button>
                                </div>
                            </AnimationOnScroll>
                        </div>
                    </AnimationOnScroll>
                </div>
            </AnimationOnScroll>
        </>
    );
}
