import { BrowserRouter } from 'react-router-dom';
import './App.css';
import Router from './routes/Router';
import ScrollToTop from './utils/ScrollToTop';
import "animate.css/animate.min.css";
import {UserProvider} from "./context/userContext";
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import Logo from "./assets/images/logo512.png";
import { useEffect } from 'react';
import { createBrowserHistory } from 'history';

function App() {

  const history = createBrowserHistory();

  return (
    <UserProvider>
      <BrowserRouter>
      <div className='App' id="App">
      <ScrollToTop />
        <Router/>
        <FloatingWhatsApp avatar={Logo} phoneNumber={'+917706868000'} accountName="Fusiontecz" style={{
            height: "10%"
           }} />
      </div>      
      </BrowserRouter>
    </UserProvider>
  );
}

export default App;
