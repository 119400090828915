import React from 'react'
import './ReviewComp.css'
import Star from "../../assets/icons/Star.png";
import User1 from "../../assets/images/user1.png";
import User2 from "../../assets/images/user2.png";
import User3 from "../../assets/images/user3.png";
import { AnimationOnScroll } from 'react-animation-on-scroll';
export default function ReviewComp() {
  return (
    <AnimationOnScroll 
    animatePreScroll={false}
    duration={1.5} 
    initiallyVisible={false}
    animateIn="animate__fadeInUp">
    <div className='review-comp'>
      <span className='review-comp-head'>Customer Reviews</span>
      <span className='review-comp-tag'>Fusiontecz Through Our <span className='review-comp-tag-ext'>Clients' Eye</span></span>
      <span className='review-comp-bio'>At Fusiontecz, we are dedicated to going above and beyond to ensure your success. Here's why we're the preferred choice and how we consistently deliver outstanding results:</span>
      <div className='reviews-cont'>
         <AnimationOnScroll 
           duration={5}
           animateIn='animate__flipInY'
           initiallyVisible={false}
            className='review-wrapper'>
          <div className='review-user-wrapper'>
            <img src={User1} alt='user'/>
            <div  className='review-user-detail'>
            <span className='review-user-name'>Shreya Sharma</span>
            <span className='review-date'>2024-March-23</span>
            </div>
          </div>
          <div className='star-container'>
            {
            [1,2,3,4,5].map(()=><img src={Star} className='stars' alt='star'/>)
            }
          </div>
          <span className='review-text'>Impressed by the seamless outsourcing solutions provided by this fintech staffing company. They've elevated our operations and efficiency.</span>
         </AnimationOnScroll>
         <AnimationOnScroll 
           duration={5}
           animateIn='animate__flipInY'
           initiallyVisible={false}
            className='review-wrapper'>
          <div className='review-user-wrapper'>
            <img src={User3} alt='user' style={{
              height:"3rem"
            }}/>
            <div className='review-user-detail'>
            <span className='review-user-name'>Subhash Panda</span>
            <span className='review-date'>2023-Aug-17</span>
            </div>
          </div>
          <div className='star-container'>
            {
            [1,2,3,4,5].map(()=><img src={Star} className='stars' alt='star'/>)
            }
          </div>
          <span className='review-text'>Excellent customer service and visibility on a global scale, They must take everyone, and you will have everything under one roof.</span>
         </AnimationOnScroll>
         <AnimationOnScroll 
           duration={5}
           animateIn='animate__flipInY'
           initiallyVisible={false}
            className='review-wrapper'>
          <div className='review-user-wrapper'>
            <img src={User2} alt='user'/>
            <div  className='review-user-detail'>
            <span className='review-user-name'>Sunita Baheja</span>
            <span className='review-date'>2021-Dec-12</span>
            </div>
          </div>
          <div className='star-container'>
            {
            [1,2,3,4,5].map(()=><img src={Star} className='stars' alt='star'/>)
            }
          </div>
          <span className='review-text'>Must for Fintech and Underwriting services, they have highly qualified professional for financial services.</span>
         </AnimationOnScroll>
      </div>
    </div>
    </AnimationOnScroll>
  )
}
