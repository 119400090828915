import React from "react";
import "../WhyWhat/whywhat.css";
import "../services/services.css";


import { AnimationOnScroll } from 'react-animation-on-scroll';

export default function WhyWhat(props){
    return(
        <>
            <div className="whywhat-container">
                <AnimationOnScroll 
                animatePreScroll={false}
                duration={2} 
                initiallyVisible={false}
                animateIn="animate__fadeInUp">
                    <div className="utility-container">
                    <AnimationOnScroll 
                    animatePreScroll={false}
                    duration={2} 
                    initiallyVisible={false}
                    animateIn="animate__fadeInLeft">
                        <div className="utility-content">
                            <h3 className="utility-title">{props.utilityContent.title}</h3>
                            <p className="utility-parah">
                            {props.utilityContent.content}
                            </p>
                        </div>
                    </AnimationOnScroll>
                    <AnimationOnScroll 
                    animatePreScroll={false}
                    duration={2} 
                    initiallyVisible={false}
                    animateIn="animate__fadeInRight">
                        <div className="utilityImage-section">
                            <img src={props.utilityContent.image} alt="utiles-image" className="utility-img" />
                        </div>
                    </AnimationOnScroll>
                    </div>
                </AnimationOnScroll>
                <AnimationOnScroll 
                animatePreScroll={false}
                duration={2} 
                initiallyVisible={false}
                animateIn="animate__fadeInUp">
                    <div className="us-container">
                    <AnimationOnScroll 
                    animatePreScroll={false}
                    duration={2} 
                    initiallyVisible={false}
                    animateIn="animate__fadeInLeft">
                        <div className="usImage-section">
                            <img src={props.usContent.image} alt="us-image" className="us-img" />
                        </div>
                    </AnimationOnScroll>
                    <AnimationOnScroll 
                    animatePreScroll={false}
                    duration={2} 
                    initiallyVisible={false}
                    animateIn="animate__fadeInRight">
                        <div className="us-content">
                            <h3 className="us-title">{props.usContent.title}</h3>
                            <p className="us-parah">
                            {props.usContent.content} 
                            </p>
                        </div>
                    </AnimationOnScroll>
                    </div>
                </AnimationOnScroll>
            </div>
        </>
    );
}