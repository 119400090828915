import React from "react";
import "../services/services.css";
import "../ServicefifthComp/servicefifth.css";
import Logo from "../../../assets/icons/Logo.png";
import { AnimationOnScroll } from 'react-animation-on-scroll';

export default function ServicefifthComp(props){
    return(
        <>
            <AnimationOnScroll 
            animatePreScroll={false}
            duration={2} 
            initiallyVisible={false}
            animateIn="animate__fadeInUp">
                <div className="servicesfifth-container">
                    <AnimationOnScroll 
                    animatePreScroll={false}
                    duration={2} 
                    initiallyVisible={false}
                    animateIn="animate__fadeInLeft">
                        <div className="logo">
                            <img src={Logo} alt="" />
                            <span>where innovation meets excellence</span>
                        </div>
                    </AnimationOnScroll>
                    <AnimationOnScroll 
                    animatePreScroll={false}
                    duration={2} 
                    initiallyVisible={false}
                    animateIn="animate__fadeInRight">
                        <div className="servicefifth-content">
                            <h4>{props.title}</h4>
                            <p>{props.content}</p>
                            <button>{props.btnContent}</button>
                        </div>
                    </AnimationOnScroll>
                </div>
            </AnimationOnScroll>
        </>
    );
}